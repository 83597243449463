<template>
  <div class="delete-register-modal fixed top-0 left-0 flex items-center justify-center w-full h-full opacity-0">
    <span
      class="end-call-modal-layer fixed top-0 left-0 w-full h-full bg-black opacity-0 cursor-pointer"
      @click="cancel"
    ></span>

    <div class="delete-register-modal__card bg-white p-10 opacity-0 rounded-lg">
      <h3 class="text-blue font-semibold text-xl mb-8">Você tem certeza de que deseja excluir esse registro?</h3>

      <div class="delete-register-modal__actions flex items-center justify-between mt-10">
        <Button light ghost class="mr-8" @click="cancel">Cancelar</Button>
        <Button @click="confirm">Confirmar exclusão</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import api from '@/utils/api.js';
import Button from "@/components/Button";

export default {
  name: "DeleteRegisterModal",
  components: { Button },
  props: ['item', 'model'],
  methods: {
    ...mapMutations(['closeDeleteRegisterModal']),

    cancel() {
      this.closeDeleteRegisterModal();
    },

    async confirm() {
      console.log('Delete:', this.item, this.model);

      try {
        await api.delete(`${this.model}/${this.item.id}`);
        this.$noty.success(`Registro #${this.item.id} deletado com sucesso`);
        this.$emit('done');
      } catch (e) {
        this.$noty.error(`Ocorreu um erro ao deletar o registro.`);
      }

      this.closeDeleteRegisterModal();
    }
  },
};
</script>

<style lang="scss">
.has-delete-register-modal {
  .delete-register-modal {
    opacity: 1;
    pointer-events: auto;
  }

  .end-call-modal-layer {
    @apply opacity-30;
  }

  .delete-register-modal__card {
    opacity: 1;
    transform: none;
  }
}

.delete-register-modal {
  z-index: 100;
  bottom: 0;
  pointer-events: none;
  overflow-y: scroll;
  transition: opacity 0.3s, transform .3s;
}

.end-call-modal-layer {
  z-index: -1;
}

.delete-register-modal__card {
  width: 540px;
  transform: scale(0.90);
  transition: transform .3s, opacity 0.3s;
}
</style>
