<template>
  <form class="search relative ml-auto text-blue bg-cream" @submit.prevent>
    <input
      type="text"
      class="py-3 pl-6 pr-12 focus:outline-none h-full"
      :value="value"
      @input="onInput"
    >
  </form>
</template>

<script>
export default {
  name: "Search",
  props: {
    value: String,
  },
  methods: {
    onInput(event) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.$emit('input', event.target.value)
      }, 300);
    }
  }
};
</script>

<style lang="scss">
.search {
  border: 1.5px solid rgba(0, 19, 111, 0.2);
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5 11h-.79l-.28-.27A6.471 6.471 0 0013 6.5 6.5 6.5 0 106.5 13c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L17.49 16l-4.99-5zm-6 0C4.01 11 2 8.99 2 6.5S4.01 2 6.5 2 11 4.01 11 6.5 8.99 11 6.5 11z' fill='%2300136F'/%3E%3C/svg%3E");
  background-position: 93% 50%;
  background-repeat: no-repeat;
  background-size: auto;
  border-radius: 60px;
}
</style>
