<template>
  <div :class="{
    'confirmation fixed top-0 left-0 w-screen h-screen flex items-center justify-center w-full h-full bg-black bg-opacity-70': true,
    'hidden pointer-events-none': state === 'close'
  }">
    <div class="bg-white p-10 rounded-lg">
      <h3 v-if="title" class="text-blue text-xl mb-6">
        {{ title }}
      </h3>

      <p v-if="description && state === 'message'" class="text-sm text-blue font-semibold font-light mb-5">
        {{ description }}
      </p>

      <Loader v-if="state === 'loading'" :isloading="state === 'loading'" />

      <div v-if="state === 'message'" class="flex items-center justify-between">
        <Button @click="cancel" light ghost>{{cancelbutton}}</Button>
        <Button @click="confirm">{{confirmbutton}}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader';
import Button from '@/components/Button';

export default {
  name: 'EndCallModal',
  components: {
    Button,
    Loader,
  },
  props: {
    'state': {
      type: String,
      default: 'close'
    },
    'title': { type: String },
    'description': { type: String },
    'cancelbutton': { type: String },
    'confirmbutton': { type: String },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang='scss'>
</style>
