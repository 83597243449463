<template>
  <div class="pagination flex items-center mt-6 px-8" v-if="paginator">
    <p class="font-light opacity-50 text-black text-xs w-6/12">
      <span>{{ meta.from }}</span> - <span>{{ meta.to }}</span> de <span> {{ meta.total }}</span> registros
    </p>

    <v-pagination
      class="pagination-nav w-6/12"
      :value="page"
      :length="meta.last_page"
      :total-visible="10"
      @input="changePage"
      circle
    />
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: ['paginator'],
  computed: {
    meta() {
      if (!this.paginator) {
        return {};
      }

      return this.paginator.meta;
    },
    page() {
      return this.meta.current_page;
    }
  },
  methods: {
    changePage(page) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });

      this.$emit('load', {
        params: { page }
      });
    }
  }
};
</script>

<style lang="scss">
.v-pagination {
  @apply justify-end;
}

:root .v-pagination__item,
:root .v-pagination__navigation {
  @apply text-blue flex text-sm items-center justify-center w-8 h-8 rounded-full p-0 shadow-none outline-none;

  &.v-pagination__item--active,
  &:hover,
  &:focus {
    @apply bg-blue bg-opacity-10 text-blue outline-none;
  }
}

:root .v-pagination .v-pagination__navigation {
  @apply mx-auto flex-shrink-0 bg-transparent;
}
</style>
